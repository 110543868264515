





































































































































































































































































































































import LazyHydrate from 'vue-lazy-hydration';
import {
  SfSkeleton,
  SkeletonLoader,
  SfAlert,
  SfButton,
  SfLink,
  SfGallery,
  SfHeading,
  SfLoader,
  SfPrice,
  SfRating,
  SfImage,
  SfReview,
  SfIcon,
  HTMLContent,
  ProductInfo,
  ProductExpandItem,
  ProductDescription,
  ProductColor,
  ProductCare,
  TogasAddToWishlist,
} from '~/components';
import ProductAddReviewForm from '~/modules/catalog/product/components/ProductAddReviewForm.vue';
import ProductGallery from '~/modules/catalog/product/components/ProductGallery.vue';

import {
  defineComponent,
  ref,
  computed,
  PropType,
  toRef,
  onMounted,
  useContext,
  useRoute,
  useRouter,
} from '@nuxtjs/composition-api';

import {
  getName as getProductName,
  getProductSku,
  getPrice as getProductPrice,
  getHeadDescription,
  getIsCustomGallery,
  getDetails,
  getCare,
  getIsSensotex,
  hasCategoryCollectionLink,
  getCategoryCollectionLabel,
  getCategoryCollectionUrl,
  getIsSaleable,
  getIsInStock,
  getIsNotifyEnable,
  getIsPromo,
  getPromoDetailsImage,
} from '~/modules/catalog/product/getters/productGetters';

import reviewGetters, {
  getTotalReviews,
  getReviewId,
  getReviewAuthor,
  getReviewDate,
  getReviewMessage,
  getReviewRating,
} from '~/modules/review/getters/reviewGetters';

import {
  useProduct,
  useReview,
  UseReviewAddReviewParams,
  useUiNotification,
  useUiState,
  useUser,
  useWishlist,
  useCart,
  useProductGallery
} from '~/composables';

import VueScrollTo from 'vue-scrollto';

import { Product } from '~/modules/catalog/product/types';

import { usePageStore } from '~/stores/page';

import {
  IN_STOCK_COLOR,
  IN_STOCK_ICON,
  IN_STOCK_TEXT,
  OUT_OF_STOCK_COLOR,
  OUT_OF_STOCK_TEXT,
  OUT_STOCK_ICON,
  ADD_TO_CART_BTN_TEXT,
  ProductPromoTypes,
} from '~/modules/catalog/product/constants';

export default defineComponent({
  name: 'SimpleProduct',
  components: {
    HTMLContent,
    LazyHydrate,
    SfSkeleton,
    SkeletonLoader,
    SfButton,
    SfLink,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfRating,
    SfAlert,
    SfImage,
    SfReview,
    SfIcon,
    TogasAddToWishlist,
    ProductGallery,
    ProductExpandItem,
    ProductInfo,
    ProductDescription,
    ProductColor,
    ProductCare,
    ProductAddReviewForm,
    ProductGalleryModal: () => import('~/modules/catalog/product/components/ProductGalleryModal.vue'),
    ProductLanding: () => import('~/modules/catalog/product/components/ProductLanding.vue'),
  },
  transition: 'fade', // TODO: needed ?
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['notify-me'],
  setup(props, { emit }) {
    const { app } = useContext();
    const route = useRoute();
    const router = useRouter();
    const product = toRef(props, 'product');
    const { isAuthenticated } = useUser();
    const { getProductPath } = useProduct();
    const { send: sendNotification } = useUiNotification();

    const {
      addItem,
      error: cartError,
      loading: isCartLoading,
      canAddToCart,
    } = useCart();

    const {
      productGallery,
      productVideoGallery,
      imageSizes,
    } = useProductGallery(product);

    const {
      addOrRemoveItem,
      isInWishlist
    } = useWishlist();

    const {
      toggleLoginModal,
      toggleNotifyMeSidebar
    } = useUiState();

    //product
    const quantity = ref(1);
    const productPrice = computed(() => getProductPrice(props.product).regular);
    const productSpecialPrice = computed(() => getProductPrice(props.product).special);
    const totalReviews = computed(() => getTotalReviews(props.product));

    const addToCartBtnText = computed(() => canAddToCart ? app.i18n.t(ADD_TO_CART_BTN_TEXT) : app.i18n.t(OUT_OF_STOCK_TEXT));
    const addToCartError = computed(() => cartError.value?.addItem?.message);
    const productColors = computed(() => props.product.color_swatches || []);
    const productColorsLength = computed(() => productColors.value.length);
    const addItemToWishlist = async ({ product }) => {
      if (isAuthenticated.value) {
        await addOrRemoveItem({ product });
      } else {
        await router.push(app.localeRoute({ name: 'customer-login-register', query: { redirect: route.value.path } }));
        sendNotification({
          id: Symbol('need_to_login'),
          message: app.i18n.tc('Need to login message') as string,
          type: 'warning',
          icon: 'check',
          persist: false,
        });
      }
    };
    const handleNotifyMe = () => emit('notify-me', props.product.sku as string);

    const ready = computed(() => product !== null && productPrice.value && stockStatusText.value); //TODO stockStatusText.value need?
    const isShowProductInfo = computed(() => {
      if (!isPromoLanding.value && ready.value) {
        return true;
      }

      if(isPromoLanding.value && isPromoLandingCalendar.value && ready.value) {
        return true;
      }
    });

    //stock data
    const stockStatusText = computed(() => {
      return canAddToCart && getIsSaleable(props.product, null, null)
        ? app.i18n.t(IN_STOCK_TEXT)
        : app.i18n.t(OUT_OF_STOCK_TEXT);
    });
    const stockStatusIcon = computed(() => {
      return canAddToCart && getIsSaleable(props.product, null, null)
        ? IN_STOCK_ICON
        : OUT_STOCK_ICON;
    });
    const stockStatusIconColor = computed(() => {
      return canAddToCart && getIsSaleable(props.product, null, null)
        ? IN_STOCK_COLOR
        : OUT_OF_STOCK_COLOR;
    });
    const stockStatusClass = computed(() => {
      return canAddToCart && getIsSaleable(props.product, null, null)
        ? 'product__stock-status-in'
        : 'product__stock-status-out';
    });
    const isInStock = computed(() => getIsInStock(props.product));

    //gallery
    const isGalleryModalOpen = ref(false);
    const activeImage = ref(0);
    const galleryModalActiveTab = ref('image');
    const handleModalGallery = (type = 'image', index = 0) => {
      galleryModalActiveTab.value = type ? type : 'image';
      if (type === 'image' && productVideoGallery.value.video_content && index >= 2) {
        activeImage.value = index - 1;
      } else {
        activeImage.value = index;
      }
      isGalleryModalOpen.value = true;
    };

    //reviews
    const { search: searchReviews, addReview } = useReview();
    const { routeData } = usePageStore();
    const isReviewsLoading = ref(true);
    const reviews = ref(null);
    const getReviewsSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
    });
    const fetchReviews = async (query = getReviewsSearchQuery()) => {
      const productReviews = await searchReviews(query);
      const baseReviews = Array.isArray(productReviews)
        ? productReviews[0]
        : productReviews;

      reviews.value = reviewGetters.getItems(baseReviews);
      isReviewsLoading.value = false;
    };
    const successAddReview = async (reviewData: UseReviewAddReviewParams) => {
      await addReview(reviewData);
    };

    //promo landings
    const isPromoLanding = computed(() => getIsPromo(props.product));
    const isPromoLandingCalendar = computed(() => props.product.product_promo_block_type === ProductPromoTypes.CALENDAR);
    const promoDetailsImage = computed(() => {
      const imagePath = getPromoDetailsImage(props.product);

      return imagePath ? imagePath : null;
    });

    const top = ref(null);
    const handleScrollTop = () => {
      VueScrollTo.scrollTo(top.value, 500, { offset: -200 });
    };

    //color tooltip
    const getTippySettings = (color) => {
      return {
        distance: '10px',
        placement: 'top-start',
        flip: false,
        theme: 'product-color',
        arrow: true,
        arrowType: 'sharp',
        content: `${color.name} ${color.swatch_color.toLowerCase()}`
      };
    };

    onMounted(() => {
      fetchReviews();
    });

    return {
      //user
      isAuthenticated,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      //user actions
      addItem,
      addItemToWishlist,
      canAddToCart,
      addToCartError,
      isCartLoading,
      toggleLoginModal,
      toggleNotifyMeSidebar,
      getProductPath,
      handleNotifyMe,
      //product info
      quantity,
      getProductName,
      getProductSku,
      getHeadDescription,
      getDetails,
      getCare,
      getIsSensotex,
      getIsSaleable,
      getIsNotifyEnable,
      productPrice,
      productSpecialPrice,
      stockStatusText,
      stockStatusIconColor,
      stockStatusIcon,
      stockStatusClass,
      isInStock,
      addToCartBtnText,
      productColors,
      productColorsLength,
      ready,
      isShowProductInfo,
      //promo landing
      isPromoLanding,
      isPromoLandingCalendar,
      promoDetailsImage,
      top,
      handleScrollTop,
      //gallery
      getIsCustomGallery,
      productGallery,
      productVideoGallery,
      imageSizes,
      handleModalGallery,
      isGalleryModalOpen,
      activeImage,
      galleryModalActiveTab,
      //reviews
      isReviewsLoading,
      totalReviews,
      reviews,
      fetchReviews,
      getReviewId,
      getReviewAuthor,
      getReviewDate,
      getReviewMessage,
      getReviewRating,
      hasCategoryCollectionLink,
      getCategoryCollectionUrl,
      getCategoryCollectionLabel,
      successAddReview,
      //color tooltip
      getTippySettings
    };
  },
});
